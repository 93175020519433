html, 
body {
    height: 100%;
}

#root{
	height: 100%;
}

.container{
	font-family: 'titillium_webregular';
	text-align: center;
	max-width: 100%;
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.banner{
	height: 140px;
	background-image: url('./assets/img/banner.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.duck-basic{
	margin-top: -60px;
}

.duck-basic .duck-image{
	width: 120px;
    margin: auto;
    background: white;
    border-radius: 50%;
	box-shadow: 1px 4px 6px rgba(174, 174, 174, 0.25);
}

.duck-basic .duck-image img{
	max-width: 100%;
}

.heading{
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 32px;
	margin: 0;
	padding: 0;
}

.card{
	background: #FFFFFF;
	box-shadow: 1px 4px 6px rgba(174, 174, 174, 0.25);
	border-radius: 10px;
	padding: 15px 25px;
	width: 900px;
	max-width: 80%;
	margin: auto;
	margin-bottom: 30px;
}

.card p{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	margin-bottom: 5px;
}

.card p.italic{
	font-style: italic;
}

.tag{
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin: 0px;
}

.instagram-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	color: #9420B1;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 10px;
}

.instagram-link{
	color: #9420B1;
	text-decoration: none;
	cursor: pointer;
}

.instagram-link:hover{
	text-decoration: none;
	color: #9420B1;
}

.instagram-icon{
	margin-right: 5px;
}

.copyright-wrapper p{
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

@font-face {
    font-family: 'titillium_webregular';
    src: url('./assets/font/titilliumweb-regular-webfont.woff2') format('woff2'),
         url('./assets/font/titilliumweb-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}